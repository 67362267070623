import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import showdown from 'showdown'

import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import { Grid, GridColumn, GridRow } from '../components/Grid'


export const query = graphql`
{
  markdownRemark(fileAbsolutePath: {regex: "/(privacy/_index)/"}) {
    frontmatter {
      seo {
        seo_description
        seo_title
      }
      welcome {
        welcome_content
        welcome_title
        welcome_image
      }
      content {
        title
        body
      }
    }
  }
}
`

const Privacy = ({ data }) => {
  const PrivacyData = data.markdownRemark.frontmatter
  const converter = new showdown.Converter()

  return(
    <Layout>
      <SEO
        title={ PrivacyData.seo.seo_title  }
        description={ PrivacyData.seo.seo_description }/>
      
      <Welcome
        title={ PrivacyData.welcome.welcome_title }
        image={ PrivacyData.welcome.welcome_image }
        content={ PrivacyData.welcome.welcome_content } />

      <Fade bottom cascade>
        <Grid>
            <GridRow>
              <GridColumn>
                  <h3>{ PrivacyData.content.title }</h3>
              </GridColumn>

              <GridColumn>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrivacyData.content.body) }}></div>
              </GridColumn>
            </GridRow>
        </Grid>
      </Fade>
    </Layout>
  )
}


export default Privacy
